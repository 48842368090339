import React from "react";
import AweInputText from "./AweInputText";
import AweInputPassword from "./AweInputPassword";
import AweInputTextarea from "./AweInputTextarea";
import AweSelect from "./AweSelect";
import AweSuggest from "./AweSuggest";
import AweInputHidden from "./AweInputHidden";
import AweInputNumeric from "./AweInputNumeric";
import AweInputDate from "./AweInputDate";
import AweInputTime from "./AweInputTime";
import AweInputFilteredDate from "./AweInputFilteredDate";
import AweInputColor from "./AweInputColor";
import AweSelectMultiple from "./AweSelectMultiple";
import AweSuggestMultiple from "./AweSuggestMultiple";
import AweInputTextView from "./AweInputTextView";
import AweInputCheckbox from "./AweInputCheckbox";
import AweInputRadio from "./AweInputRadio";
import AweButtonCheckbox from "./AweButtonCheckbox";
import AweButtonRadio from "./AweButtonRadio";
import AweInputUploader from "./AweInputUploader";
import AweInputEditor from "./AweInputEditor";

const CriteriaList = {
  "hidden": AweInputHidden,
  "text": AweInputText,
  "password": AweInputPassword,
  "textarea": AweInputTextarea,
  "select": AweSelect,
  "suggest": AweSuggest,
  "numeric": AweInputNumeric,
  "date": AweInputDate,
  "time": AweInputTime,
  "filtered-calendar": AweInputFilteredDate,
  "color": AweInputColor,
  "select-multiple": AweSelectMultiple,
  "suggest-multiple": AweSuggestMultiple,
  "text-view": AweInputTextView,
  "checkbox": AweInputCheckbox,
  "radio": AweInputRadio,
  "button-checkbox": AweButtonCheckbox,
  "button-radio": AweButtonRadio,
  "uploader": AweInputUploader,
  "markdown-editor": AweInputEditor
};

export const Criteria = (node, index) => {
  if (typeof CriteriaList[node.component] !== "undefined") {
    return React.createElement(CriteriaList[node.component], {
      ...node, key: node.id || `criterion-${index}`
    });
  }
  return React.createElement(
    () => <div>The criterion {node.component} has not been created yet.</div>,
    {key: index}
  );
};
