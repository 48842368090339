import AweButton from "./AweButton";
import Tag from "./Tag";
import SubView from "./SubView";
import Empty from "./Empty";
import AweWindow from "./AweWindow";
import AweDialog from "./AweDialog";
import AweMenu from "./AweMenu";
import AweInfoDropdown from "./AweInfoDropdown";
import AweInfoButton from "./AweInfoButton";
import AweInfoCriterion from "./AweInfoCriterion";
import AweTabs from "./AweTabs";
import AweSteps from "./AweSteps";
import AweChart from "./AweChart";
import AweTagList from "./AweTagList";
import {AweResizable} from "./AweResizable";
import {AweAccordion} from "./AweAccordion";
import AweWidget from "../widgets";
import AwePivotTable from "./AwePivotTable";
import AweGrid from "./AweGrid";
import AweTreeGrid from "./AweTreeGrid";
import {Criteria} from "../criteria/AweCriteria";
import AweAvatar from "./AweAvatar";
import React from "react";

export const ComponentList = {
  "Button": AweButton,
  "Tag": Tag,
  "Info": AweInfoDropdown,
  "InfoButton": AweInfoButton,
  "InfoCriteria": AweInfoCriterion,
  "Avatar": AweAvatar,
  "Screen": Empty,
  "Include": Empty,
  "View": SubView,
  "Window": AweWindow,
  "Dialog": AweDialog,
  "Grid": AweGrid,
  "TreeGrid": AweTreeGrid,
  "PivotTable": AwePivotTable,
  "Tab": AweTabs,
  "Wizard": AweSteps,
  "TabContainer": Empty,
  "WizardPanel": Empty,
  "Chart": AweChart,
  "MenuContainer": AweMenu,
  "Criteria": Criteria,
  "TagList": AweTagList,
  "Resizable": AweResizable,
  "Accordion": AweAccordion,
  "Widget": AweWidget
};

const IGNORE_LIST = ["ButtonAction", "Dependency"];

export const Components = (node, index) => {
  if (IGNORE_LIST.includes(node.elementType)) return null;
  if (typeof ComponentList[node.elementType] !== "undefined") {
    return React.createElement(ComponentList[node.elementType], {
      ...node, key: node.id || `component-${index}`
    });
  }
};
