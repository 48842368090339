import {getFirstDefinedValue, isEmpty} from "./index";

/**
 * Number utility functions
 * @category Utilities
 * @namespace Numbers
 */

/**
 * Get first defined value as number
 * @returns {number} First number defined
 * @memberOf Numbers
 */
export function getFirstDefinedValueAsNumber() {
  let value = getFirstDefinedValue.apply(this, arguments);
  return !isNaN(Number(value)) ? Number(value) : undefined;
}

/**
 * Get locale from separator
 * @param separator Separator
 * @returns {string} Locale
 * @memberOf Numbers
 */
function getLocaleFromSeparator(separator) {
  switch (separator) {
    case ",":
      return "en-US";
    case ".":
    default:
      return "de";
  }
}

/**
 * Translate a number format into parts
 * @param {object} numberFormat number format
 * @returns {{minFractionDigits: *, min: *, max: *, maxFractionDigits: *, locale: string, suffix: *}}
 * @memberOf Numbers
 */
export function translateNumberFormat(numberFormat) {
  let decimals = getFirstDefinedValueAsNumber(numberFormat.mDec, numberFormat.precision, numberFormat.decimalPlaces, 0);
  let isPrefix = numberFormat.pSign === "p";
  let prefixSuffix = getFirstDefinedValue(numberFormat.aSign, numberFormat.currencySymbol, "");
  return {
    locale: getLocaleFromSeparator(getFirstDefinedValue(numberFormat.aSep, numberFormat.digitGroupSeparator, undefined)),
    maxFractionDigits: decimals,
    minFractionDigits: getFirstDefinedValue(numberFormat.aPad, numberFormat.allowDecimalPadding, true) ? decimals : 0,
    prefix: isPrefix ? prefixSuffix : "",
    suffix: !isPrefix ? prefixSuffix : "",
    min: getFirstDefinedValueAsNumber(numberFormat.min, numberFormat.vMin, numberFormat.minimumValue, undefined),
    max: getFirstDefinedValueAsNumber(numberFormat.max, numberFormat.vMax, numberFormat.maximumValue, undefined)
  }
}

/**
 * Format a number given a numberFormat
 * @param number Number to format
 * @param numberFormat Format parameters
 * @return {string} Formatted number
 */
export function formatNumber(number, numberFormat) {
  const {maxFractionDigits, minFractionDigits, prefix, suffix, locale} = translateNumberFormat(numberFormat);
  const value = isNumber(number) ? number : parseFloat(number);
  return isEmpty(number) ? "" : prefix +
    value.toLocaleString(locale, {minimumFractionDigits: minFractionDigits, maximumFractionDigits: maxFractionDigits}) +
    suffix;
}

/**
 * Check if a value is a number
 * @param value Value to test
 * @return {boolean} Value is number
 */
export function isNumber(value) {
  return !isEmpty(value) && typeof value === "number";
}
