import React from "react";
import {connectComponent} from "../components/AweComponent";
import {AutoComplete} from "primereact/autocomplete";
import AweSuggestComponent from "./AweSuggestComponent";

class AweSuggestMultiple extends AweSuggestComponent {

  onChange(e) {
    const {address, updateModelWithDependencies} = this.props;
    const uniqueSuggest = _.uniqBy(this.state.suggest, "value");
    const uniqueValue = _.uniqBy(e.value, "value");
    if (uniqueSuggest.length !== uniqueValue.length) {
      updateModelWithDependencies(address, {values: uniqueValue.map(item => ({...item, selected: true}))});
    }
  }

  onSelect(e) {
    const {address, updateModelWithDependencies} = this.props;
    const uniqueSuggest = _.uniqBy(this.state.suggest, "value").map(t => t.value);
    if (!uniqueSuggest.includes(e.value?.value || null)) {
      updateModelWithDependencies(address, {values: [...this.state.suggest, e.value].flat().map(item => ({...item, selected: true}))});
    }
  }

  getComponent(style) {
    const {t, address, placeholder, required, readonly, timeout} = this.props;
    const {suggest, suggestions} = this.state;
    return <AutoComplete multiple
      ref={el => this.autocomplete = el}
      id={address.component}
      value={suggest.map(item => ({...item, label: item.label || item.value}))}
      placeholder={t(placeholder)}
      required={required}
      disabled={readonly}
      onChange={this.onChange}
      onSelect={this.onSelect}
      onKeyPress={this.onKeyPress}
      delay={timeout || 300}
      field="label"
      suggestions={suggestions}
      completeMethod={this.suggest}
      className={style}
      appendTo={document.body}
    />;
  }
}

export default connectComponent(AweSuggestMultiple);
