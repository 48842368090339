import React from "react";
import {connectComponent} from "../components/AweComponent";
import {bindMethods, getIconCode} from "../utilities";
import AweCriterionComponent from "./AweCriterionComponent";
import {classNames} from "../utilities/components";

class AweInputTextView extends AweCriterionComponent {

  constructor(props) {
    super(props);
    this.groupClass = "flex p-2";
    bindMethods(this, ["getComponent"]);
  }

  getIcon() {
    const {icon} = this.props.attributes;
    if (icon) {
      return getIconCode(icon, "fa-fw");
    }

    return null;
  }

  getUnit() {
    const {t, attributes} = this.props;
    const {unit} = attributes;
    if (unit) {
      return <span className="p-tag ml-auto">{t(unit)}</span>;
    }

    return null;
  }

  getComponent(style) {
    const {model, attributes} = this.props;
    const {placeholder, size} = attributes;
    const {values} = model;
    const classes = classNames({[`p-inputtext-${size}`]: size}, style);

    return <span className={classes}>{(values[0] || {}).label || placeholder}</span>;
  }
}

export default connectComponent(AweInputTextView);
