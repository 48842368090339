import React from "react";
import AweFileManager from "./AweFileManager";
import AweLogViewer from "./AweLogViewer";
import AweHelpViewer from "./AweHelpViewer";

const Widget = {
  "file-manager": AweFileManager,
  "log-viewer": AweLogViewer,
  "help-viewer": AweHelpViewer
};

export default (node, index) => {
  if (typeof Widget[node.type] !== "undefined") {
    return React.createElement(Widget[node.type], {
      ...node, key: node.id || `widget-${index}`
    });
  }
  return React.createElement(
    () => <div>The widget {node.type} has not been created yet.</div>,
    {key: index}
  );
};
