import React from "react";
import FullTemplate from "./FullTemplate";
import WindowTemplate from "./WindowTemplate";

const Templates = {
  "full": FullTemplate,
  "window": WindowTemplate
};

export default (node) => {
  if (typeof Templates[node.screenTemplate] !== "undefined") {
    return React.createElement(Templates[node.screenTemplate], {
      ...node
    });
  }
  return React.createElement(
    () => <div>The template {node.screenTemplate} has not been created yet.</div>
  );
};