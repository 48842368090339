import React from "react";
import {connectComponent} from "../components/AweComponent";
import {Dropdown} from "primereact/dropdown";
import AweCriterionComponent from "./AweCriterionComponent";
import {bindMethods} from "../utilities";

class AweSelect extends AweCriterionComponent {

  constructor(props) {
    super(props);

    bindMethods(this, ["getComponent", "getValue", "onChange"])
  }

  onChange(e) {
    const {address, model, updateModelWithDependencies} = this.props;
    updateModelWithDependencies(address, {
      values: model.values.map(d => ({
        ...d,
        selected: d.value === e.target.value
      }))
    });
  }

  getValue() {
    return this.props.model.values.filter(v => v.selected).map(v => v.value)[0] || null;
  }

  getComponent(style) {
    const {t, address, attributes, model} = this.props;
    const {placeholder, required, readonly, optional} = attributes;
    return <Dropdown
      ref={el => this.dropdown = el}
      id={address.component}
      value={this.getValue()}
      placeholder={t(placeholder)}
      onChange={this.onChange}
      required={required}
      disabled={readonly}
      className={style}
      options={model.values.map(value => ({...value, label: t(value.label)}))}
      showClear={optional}
      filter={model.values.length > 5}
      filterBy="label"
    />;
  }
}

export default connectComponent(AweSelect);