import React from "react";
import {connectComponent} from "./AweComponent";
import {Steps} from "primereact/steps";
import {AwePanelableComponent} from "./AwePanelableComponent";
import {Components} from "./AweComponents";

class AweSteps extends AwePanelableComponent {

  render() {
    const {model, elementList, t, disabled} = this.props;
    const activeIndex = this.getActiveIndex();
    return <div className={"p-steps-container expand expandible-vertical"}>
      <Steps model={model.values.map(item => ({...item, label: t(item.label), disabled}))}
             activeIndex={activeIndex}
             onTabChange={this.onChange}
             ref={(el) => this.steps = el}/>
      {elementList.filter((item, index) => index === activeIndex).map((node, index) => Components(node, index))}
    </div>;
  }
}

export default connectComponent(AweSteps);
